<template>
  <div class="d-flex align-center flex-nowrap flex-grow-1 column-ref">
    <!-- {{valTy}} -->
    <!-- {{iconName}} -->
    <document-ref-icon
      :visible="showIcon"
      :icon-name="iconName"
      :mixed="mixed"
      :x-small="_iconProps.xsmall"
      :small="_iconProps.small"
      :normal="_iconProps.normal"
      :large="_iconProps.large"
      :x-large="_iconProps.xlarge"
    />
    <icon-tooltip
      class="mr-2"
      v-if="hasError"
      :disabled="!isDeleted"
      icon-name="i.ExclamationThick"
      :tooltip-text="tooltipTextError"
      color="error"
    />
    <div class="d-flex flex-column">
      <v-tooltip
        open-delay="200"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            class="nowrap text-truncate max-width-text"
          >{{name}}</span>
        </template>
        <span>{{name}}</span>
      </v-tooltip>
      <span
        class="align-baseline nowrap d-flex flex-row"
        v-if="innerOptions.showDetail"
      >
        <slot name="doc-type" />
        <span class="ref-detail">
          <span class="mx-1">{{$t('t.On').toLowerCase()}}</span>
          <span class="nowrap text-truncate max-width-text">"{{viewTypeText}}"</span>
        </span>
      </span>
    </div>
    <v-btn
      icon
      small
      class="ml-2"
      @click.stop="handleEdit"
      v-if="canEdit"
    >
      <icon-tooltip
        small
        :icon-name="'i.OpenInSidePanel'"
        :tooltip-text="$t('t.OpenInSidePanel')"
      />
    </v-btn>
  </div>
</template>

<script>
import sidePanelController from '@/sidePanelController'
import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  computed: {
    tooltipTextError () {
      return this.isDeleted ? this.$t('t.ColumnIsDeleted') : undefined
    },
    viewTypeText () {
      return this.computedViewType ? this.$t(`t.${this.toPascalCase(this.computedViewType)}`) : ''
    },
    viewTypeIcon () {
      return this.computedViewType ? `i.${this.toPascalCase(this.computedViewType)}` : ''
    },
    canEdit () {
      return this.canEditColumn && this.paramsTy && this.paramsTy !== 'none'
    },
    hasError () {
      return this.cache?.doc?.hasError || this.cache?.doc?.isDeleted
    },
    name () {
      return this.id ? this.cache?.doc?.name : this.$t('t.NewColumn')
    },
    computedViewType () {
      return this.id ? this.cache?.doc?.viewType : this.viewType
    },
    paramsTy () {
      return this.cache?.doc?.paramsTy
    },
    valTy () {
      return this.cache?.doc?.valTy
    },
    isDeleted () {
      return this.cache?.doc?.isDeleted
    },
    isObject () {
      return typeof this.valTy === 'object'
    },
    isSystem () {
      return this.cache?.doc?.isSystem
    },
    innerOptions () {
      return {
        showDetail: this.options?.showDetail ?? false
      }
    },
    iconName () {
      if (!this.cache?.doc) { return 'i.Columns' }

      const valueType = this.valTy ? Object.keys(this.valTy)[0] : null
      if (valueType) {
        switch (valueType) {
          case 'list':
            return `i.${this.toPascalCase(this.valTy.list.document.ty)}`
          case 'document':
            return this.valTy.document.ty.includes('groupama-decision-codes') || this.valTy.document.ty.includes('status') ? 'i.Status' : `i.${this.toPascalCase(this.valTy.document.ty)}`
          case 'file':
            return 'i.Paperclip'
          case 'bool':
            return 'i.Bool'
          case 'string':
          case 'translated':
            return 'i.String'
          case 'date':
            return 'i.Calendar'
          case 'duration':
            return 'i.Duration'
          case 'integer':
            return 'i.Integer'
          case 'amt':
          case 'decimal':
          case 'balance-slash-amount':
            return 'i.Amount'
          case 'badge-links':
            return 'i.Link'
          case 'assigned':
            return 'i.AssignedTo'
          case 'id-number':
            return 'i.Layers'
          case 'create-update-at':
            return 'i.CreateUpdateAt'
          case 'comment':
            return 'i.CommentEdit'
          case 'graph':
            return 'i.Graph'
          case 'smart-email-sender':
            return 'i.Users'
          case 'icon':
            return 'i.Attachment'
        }

        if (valueType.includes('status')) {
          return 'i.Status'
        }
      }

      return null
    },
    _iconProps () {
      const _ = Object.assign({
        xsmall: false,
        small: false,
        normal: false,
        large: false,
        xlarge: false
      }, this.iconProps)

      return _
    }
  },
  data () {
    return {
      cacheType: ClientCache.CacheType.ColumnRef
      // iconName: 'i.Columns'
    }
  },
  methods: {
    handleEdit () {
      sidePanelController.displayDocument(this.id, 'columns', { targetDocTy: this.computedViewType })
    }
  },
  mixins: [ClientCache],
  props: {
    canEditColumn: Boolean,
    id: String,
    iconProps: Object,
    options: Object,
    mixed: Boolean,
    showIcon: {
      type: Boolean,
      default: true
    },
    viewType: String
  }
}
</script>

<style lang="stylus" scoped>
.name
  margin-left 1px

.max-width-text
  max-width 250px
</style>
